import { IGoodWriteOffInfo } from "../../models/store/good-write-off-info";
import { Currency, IGoodRegistrationItem, IProductInfo, IWarranty, Locale } from "../../models";
import { IGoodInventoryInfo } from "../../models/store/good-inventory-info";

export interface IStoreCreateDto {
    name: string;
    shortName?: string;
    description?: string;
    //avatar: string;
    currency?: Currency;
    locale?: Locale;
}

export interface IStoreUpdateDto {
    name?: string;
    shortName?: string;
    description?: string;
    //avatar: string;
    currency?: Currency;
    locale?: Locale;
}

//

export interface IGoodCreateDto {
    //good?: string;
    info: IProductInfo;
    group?: string;
    place?: string;
    tags?: string[];
    quantityThreshold?: number;
}

export interface IGoodUpdateDto {
    group?: string;
    place?: string;
    info?: IProductInfo;
    tags?: string[];
    quantityThreshold?: number;
}

export interface IGoodMoveDto {
    storeId: string;
    quantity: number;
}

export interface IGoodMoveItemDto {
    id: string;
    quantity: number;
}

export interface IGoodMoveManyDto {
    items: IGoodMoveItemDto[];
    storeId: string;
}

export interface IGoodDeleteManyDto {
    ids: string[];
}

//

export interface IGoodRegistrationItemDto {
    info: IProductInfo;
    group?: string;
    place?: string;
    tags?: string[];
    quantityThreshold?: number;
    good?: string;
}

export interface IGoodRegistrationCreateDto {
    storeId: string;
    userId: string;
    supplierId?: string;
    accountId?: string;
    transactionDescription?: string;
    goods: IGoodRegistrationItemDto[];
    amount: number;
    comment: string;
    tags?: string[];
    // date: string | Date;
}

export interface IGoodRegistrationCreateDao {
    storeId: string;
    userId: string;
    supplierId?: string;
    accountId?: string;
    goods: IGoodRegistrationItem[];
    amount: number;
    comment: string;
    tags?: string[];
    // date: string | Date;
}

export interface IGoodRegistrationUpdateDto {
    date?: Date;
    goods?: IGoodCreateDto[];
    amount?: number;
    store?: string;
    tags?: string[];
    account?: string;
    comment?: string;
    supplierId?: string;
}

export interface IGoodRegistrationDeleteManyDto {
    ids: string[];
}

//

export interface IGoodWriteOffCreateDto {
    storeId: string;
    employeeId: string;
    goods: IGoodWriteOffInfo[];
    comment: string;
    amount: number;
}

export interface IGoodWriteOffUpdateDto {
    goods?: IGoodWriteOffInfo[];
    comment?: string;
}

export interface IGoodWriteOffDeleteManyDto {
    ids: string[];
}

//

export interface IGoodInventoryCreateDto {
    version?: number;
    storeId: string;
    employeeId: string;
    /**
     * Информация о товарах.
     * @deprecated Свойство устарело и будет удалено. Вместо него используется модель `IGoodInventoryItem`.
     * */
    goods: IGoodInventoryInfo[];
    comment?: string;
    done?: boolean;
}

export interface IGoodInventoryUpdateDto {
    /**
     * Информация о товарах.
     * @deprecated Свойство устарело и будет удалено. Вместо него используется модель `IGoodInventoryItem`.
     * */
    goods?: IGoodInventoryInfo[];
    comment?: string;
    done?: boolean;
}

export interface IGoodInventoryItemCreateDto {
    good?: string;
    sku?: string;
    name: string;
    cost: number;
    price: number;
    quantity: number;
    inventoryQuantity: number;
}

export interface IGoodInventoryItemUpdateDto {
    good?: string;
    sku?: string;
    name?: string;
    cost?: number;
    price?: number;
    quantity?: number;
    inventoryQuantity?: number;
}

export interface IGoodInventoryItemReportResult {
    /** Излишек. */
    surplus: number;

    /** Недостача. */
    shortage: number;
}

//

export interface IGoodImportDto {
    tags?: string[];
}

//

export interface IGoodCategoryCreateDto {
    stores: string[];
    sequence?: number;
    name: string;
    description?: string;
    color?: string;
}

export interface IGoodCategoryUpdateDto {
    stores: string[];
    sequence?: number;
    name?: string;
    description?: string;
    color?: string;
}

//

export interface ISupplierCreateDto {
    sequence?: number;
    name: string;
    description?: string;
    address?: string;
    phone?: string;
    email?: string;
}

export interface ISupplierUpdateDto {
    sequence?: number;
    name?: string;
    description?: string;
    address?: string;
    phone?: string;
    email?: string;
}

//

export interface IGoodHistoryItem {
    id: string;
    type: GoodHistoryItemType;
    userId: string;
    datetime: Date;
    meta: any;
}

export enum GoodHistoryItemType {
    Registration = "registration",
    WriteOff = "writeoff",
    OrderMaterial = "order-material",
    Sale = "sale",
}
