import {
    IGoodRegistration,
    IGoodRegistrationUseCase,
    IGoodRegistrationCreateDto,
    IGoodRegistrationUpdateDto,
    ISelectedData,
    ISelectQuery,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class GoodRegistrationUseCase implements IGoodRegistrationUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IGoodRegistration>> {
        try {
            const response = await Api.get<ISelectedData<IGoodRegistration>>(
                `/companies/${companyId}/good-registrations`,
                { params: query },
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IGoodRegistration> {
        try {
            const response = await Api.get<IGoodRegistration>(`/companies/${companyId}/good-registrations/${id}`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodRegistrationCreateDto): Promise<IGoodRegistration> {
        try {
            const response = await Api.post<IGoodRegistration>(`/companies/${companyId}/good-registrations/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IGoodRegistrationUpdateDto): Promise<IGoodRegistration> {
        try {
            const response = await Api.put<IGoodRegistration>(`/companies/${companyId}/good-registrations/${id}`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-registrations/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
