import {
    IGoodWriteOff,
    IGoodWriteOffUseCase,
    IGoodWriteOffCreateDto,
    IGoodWriteOffUpdateDto,
    ISelectedData,
    ISelectQuery,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class GoodWriteOffUseCase implements IGoodWriteOffUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IGoodWriteOff>> {
        try {
            const response = await Api.get<ISelectedData<IGoodWriteOff>>(`/companies/${companyId}/good-write-offs`, {
                params: query,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IGoodWriteOff> {
        try {
            const response = await Api.get<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/${id}`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodWriteOffCreateDto): Promise<IGoodWriteOff> {
        try {
            const response = await Api.post<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IGoodWriteOffUpdateDto): Promise<IGoodWriteOff> {
        try {
            const response = await Api.put<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/${id}`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-write-offs/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
